export const products = [
  {
    id: 1,
    name: "Classic White T-Shirt",
    price: 120000,
    image: "https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?w=500",
    category: "T-Shirts",
    description: "Essential white t-shirt made from premium cotton.",
  },
  {
    id: 2,
    name: "Vintage Denim Jacket",
    price: 350000,
    image: "https://images.unsplash.com/photo-1551537482-f2075a1d41f2?w=500",
    category: "Jackets",
    description: "Classic denim jacket with a vintage wash.",
  },
  {
    id: 3,
    name: "Black Hoodie",
    price: 425000,
    image: "https://images.unsplash.com/photo-1556821840-3a63f95609a7?w=500",
    category: "Hoodies",
    description: "Comfortable black hoodie perfect for any occasion.",
  },
  {
    id: 4,
    name: "Striped Polo Shirt",
    price: 170000,
    image: "https://images.unsplash.com/photo-1576566588028-4147f3842f27?w=500",
    category: "T-Shirts",
    description: "Classic striped polo shirt for a smart casual look.",
  },
  {
    id: 5,
    name: "Leather Biker Jacket",
    price: 300000,
    image: "https://images.unsplash.com/photo-1521223890158-f9f7c3d5d504?w=500",
    category: "Jackets",
    description: "Genuine leather jacket with authentic biker style.",
  },
  {
    id: 6,
    name: "Gray Zip-up Hoodie",
    price: 350000,
    image: "https://images.unsplash.com/photo-1578768079052-aa76e52ff62e?w=500",
    category: "Hoodies",
    description: "Versatile gray zip-up hoodie for everyday wear.",
  },
  {
    id: 7,
    name: "Graphic Print T-Shirt",
    price: 100000,
    image: "https://images.unsplash.com/photo-1503341455253-b2e723bb3dbb?w=500",
    category: "T-Shirts",
    description: "Unique graphic print t-shirt with artistic design.",
  },
  {
    id: 8,
    name: "Bomber Jacket",
    price: 400000,
    image: "https://images.unsplash.com/photo-1591047139829-d91aecb6caea?w=500",
    category: "Jackets",
    description: "Classic bomber jacket with modern details.",
  },
  {
    id: 9,
    name: "Pullover Hoodie",
    price: 450000,
    image: "https://images.unsplash.com/photo-1556821840-3a63f95609a7?w=500",
    category: "Hoodies",
    description: "Cozy pullover hoodie for cold weather.",
  },
  {
    id: 10,
    name: "V-Neck T-Shirt",
    price: 150000,
    image: "https://images.unsplash.com/photo-1581655353564-df123a1eb820?w=500",
    category: "T-Shirts",
    description: "Classic v-neck t-shirt for a sleek look.",
  },
  {
    id: 11,
    name: "Windbreaker Jacket",
    price: 325000,
    image: "https://images.unsplash.com/photo-1545594861-3bef43ff2fc8?w=500",
    category: "Jackets",
    description: "Lightweight windbreaker for protection against elements.",
  },
  {
    id: 12,
    name: "Tech Fleece Hoodie",
    price: 335000,
    image: "https://images.unsplash.com/photo-1556821840-3a63f95609a7?w=500",
    category: "Hoodies",
    description: "Advanced tech fleece hoodie for superior comfort.",
  },
];

export const articles = [
  {
    id: 1,
    title: "The Future of Sustainable Fashion",
    excerpt:
      "Exploring how sustainable practices are reshaping the fashion industry and what it means for consumers.",
    content: "Lorem ipsum dolor sit amet...",
    image: "https://images.unsplash.com/photo-1523381210434-271e8be1f52b?w=800",
    date: "2024-03-15",
    author: "Emma Thompson",
    readTime: "8 min read",
    featured: true,
  },
  {
    id: 2,
    title: "Spring Fashion Trends 2024",
    excerpt:
      "Discover the hottest trends that will dominate the fashion scene this spring season.",
    content: "Lorem ipsum dolor sit amet...",
    image: "https://images.unsplash.com/photo-1490481651871-ab68de25d43d?w=500",
    date: "2024-03-10",
    author: "Michael Chen",
    readTime: "5 min read",
    featured: false,
  },
  {
    id: 3,
    title: "The Art of Layering",
    excerpt:
      "Master the technique of layering clothes for both style and functionality.",
    content: "Lorem ipsum dolor sit amet...",
    image: "https://images.unsplash.com/photo-1485230895905-ec40ba36b9bc?w=500",
    date: "2024-03-08",
    author: "Sarah Parker",
    readTime: "6 min read",
    featured: false,
  },
  {
    id: 4,
    title: "Minimalist Wardrobe Guide",
    excerpt: "How to build a versatile wardrobe with fewer, better pieces.",
    content: "Lorem ipsum dolor sit amet...",
    image: "https://images.unsplash.com/photo-1434389677669-e08b4cac3105?w=500",
    date: "2024-03-05",
    author: "David Wilson",
    readTime: "7 min read",
    featured: false,
  },
  {
    id: 5,
    title: "Care Guide for Premium Fabrics",
    excerpt: "Essential tips for maintaining your high-quality garments.",
    content: "Lorem ipsum dolor sit amet...",
    image: "https://images.unsplash.com/photo-1441986300917-64674bd600d8?w=500",
    date: "2024-03-03",
    author: "Lisa Brown",
    readTime: "4 min read",
    featured: false,
  },
  {
    id: 6,
    title: "Street Style Around the World",
    excerpt:
      "Exploring unique fashion expressions from different cultures and cities.",
    content: "Lorem ipsum dolor sit amet...",
    image: "https://images.unsplash.com/photo-1509631179647-0177331693ae?w=500",
    date: "2024-03-01",
    author: "James Rodriguez",
    readTime: "6 min read",
    featured: false,
  },
  {
    id: 7,
    title: "Sustainable Fabric Innovations",
    excerpt: "New eco-friendly materials revolutionizing the fashion industry.",
    content: "Lorem ipsum dolor sit amet...",
    image: "https://images.unsplash.com/photo-1606913084603-3e7702b01627?w=500",
    date: "2024-02-28",
    author: "Anna Kim",
    readTime: "5 min read",
    featured: false,
  },
];
